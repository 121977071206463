/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "nulshock";
  src: url(@assets/fonts/Nulshock-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "pangram";
  src: url(@assets/fonts/Pangram-Regular.otf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "pangram";
  src: url(@assets/fonts/Pangram-Medium.otf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "pangram";
  src: url(@assets/fonts/Pangram-Bold.otf);
  font-style: normal;
  font-weight: 600;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #F6F6F6 inset !important;
}


.information_model li{
  display: block;
  float: none !important;
}
.css-upqper{
  width: 100% !important;
  max-width: 600px !important;
}
.information_model{width: 48%;float: left;margin-top: 15px;padding-left: 2%;}