* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #f4f4f4;
}

/*================NAV CONTAINER ID====================*/
#element-container {
  width: 100%;
  height: 4.5em;
  background-color: #1E2120;
  position: fixed;
  top: 0;
  z-index: 9999;
}

/*==============HIRING REALME LOGO====================*/
#logo {
  width: 12.25em;
  height: 1.625em;
  display: inline-block;
  /* margin-right: 20px;*/
  position: relative;
  /* 50% of vertical distance - 25px (half of the .element height) will vertically align it */
  top: calc(50% - 13px);
}

.element {
  margin-left: 3.0625em;
}


/*=======DIV WITH THE NOT A MEMBER LINK AND REGISTER BUTTON====================*/
.right_nav {
  float: right;
  height: 2.75em;
  margin-right: 3.0625em;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 25%;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 12px 14px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #111;
}

/*===========NOT A MEMBER LINK?===================*/
.not-member-link {
  padding-right: 1.1875em;
  border-right: 1px solid #fff;
  margin-right: 1.625em;
}

/*=======REGISTER BUTTON====================*/
.link-button {

  background: #FFA842 none repeat scroll 0 0;
  border: none;
  width: 8.5em;
  height: 2.75em;
  color: #fff;
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none;
  pointer-events: none;
}

nav a {
  font-size: 16px;
  font-family: pangram;
  font-weight: 400;

}


/*===========CONTAINER======================*/
.content_container {
  width: 93.5%;
  background-color: #fff;
  border-radius: 0 0 30px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 49px;
}

.content-center {
  margin-top: 61px;
}

/*===============CONTAINER=====================*/
.content-center #container {
  width: 35.9375em;
  margin-top: 61px;
}

/*========Illustration CONTAINER-=========*/
.illustration-container {
  margin-top: 77px;
  margin-bottom: 42px;
  width: 450px;
  height: 327px;
}

.illustration-container img {
  margin-left: 60px;
}

/*=====Heading container======*/
.h1-container {
  text-align: center;
  margin-bottom: 37px;
}

.h1-container h1, h2 {
  font-size: 28px;
  font-weight: 500;
  font-family: pangram;
  color: #000;
}

/*=====Set up profile container======*/
.set-up-profile {
  margin-bottom: 148px;
  text-align: center;
}

.set-up-profile a button {
  width: 334px;
  height: 63px;
  background-color: #1E2120;
  color: #fff;
  border-radius: 8px;
  border: none;
  font-family: pangram;
  font-size: 500;
  font-size: 16px;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.87);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; rgba(0, 0, 0, 0.87);  */
  background: rgb(142, 144, 143);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.faqAccordion {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.accordionRoot::before {
  background-color: transparent !important;
}

.pointsYearly {
  color: #fff;
  width: 80%;
  text-align: left;
}

.pointsMonthly {
  color: #000;
  width: 80%;
  text-align: left;
}

.titleYearly {
  padding-top: 20px;
  color: rgba(255, 145, 65, 1);
}

.titlenoPrice {
  padding-top: 20px;
  /* font-size: 24px; */
  /* margin-bottom: 4px; */
  color: rgba(255, 145, 65, 1);
}

.text-secondary {
  color: rgba(255, 145, 65, 1) !important;
}

.gPopup {
  background-color: rgba(255, 145, 65, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
  min-width: 20px !important;
  padding: 0% !important;
  height: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  right: 30px;
  top: 8px;
}

.gPopupM {
  background-color: rgba(255, 145, 65, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
  min-width: 20px !important;
  padding: 0% !important;
  height: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  right: 0px;
  top: -5px;
}

.gPopup1 {
  background-color: rgba(255, 145, 65, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
  min-width: 20px !important;
  padding: 0% !important;
  height: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  /* right: 100px; */
  top: 8px;
}

.popupImage {
  width: 100%;
  cursor: pointer;
}

.popupImage1 {
  width: 45%;
  cursor: pointer;
}

.rowItem {
  display: flex;
}

.popuptext {
  font-size: 12px;
  color: red;
  margin-left: 20px;
  margin-top: 5px
}

.popuptext1 {
  font-size: 12px;
  color: red;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 5px
}

.rowBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.titleMonthly {
  padding-top: 20px;
  color: rgba(51, 51, 51, 1);
  opacity: 0.5;
}

.priceYearly {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.priceMonthly {
  font-weight: bold;
  color: #000;
  font-size: 24px;
}

.durationYearly {
  color: rgba(244, 244, 244, 0.63);
}

.durationMonthly {
  color: rgba(30, 33, 32, 1);
}

.discount {
  float: right;
  margin-right: -1px;
  margin-left: -50px;
  top: 0;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 145, 65, 1);
}

.roundRadius {
  border-radius: 10px !important;
}

.cardBox {
  background-color: #F6F6F6;
  border: 1px solid #dad8d8;
  border-radius: 5px;
  height: 55px;
  padding-top: 18px;
  padding-left: 10px;

}

.discountPrice {
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
}

.discountText {
  font-size: 12px;
  color: #fff
}

.rowCenter {
  display: flex;
  align-items: center;
}